import { useState, useEffect } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import axios from 'utilities/authAxios';
import { useRecoilValue } from 'recoil';
import getAccountStatusIdByDesc from 'utilities/Helpers';
import { globalAccountStatusId, accountStatusDescsAtom } from 'utilities/State';
import PropTypes from 'prop-types';
import WithLoader from 'components/Custom/WithLoader';
import FormDialog from 'components/Custom/FormDialog';
import VehiclesCard from './VehiclesCard';
import AddVehicleDialogForm from './AddVehicleDialogForm';
import VehicleDetailsGrid from './VehicleDetailsGrid';

function VehiclesPage(props) {
  const [vehicles, setVehicles] = useState([]);
  const [plateStatusDescs, setPlateStatusDescs] = useState({});
  const [refresh, toggleRefresh] = useState(false);
  const { accountId } = useParams();
  const accountStatusDescs = useRecoilValue(accountStatusDescsAtom);
  const accountStatusId = useRecoilValue(globalAccountStatusId);

  const plateStatusLookup = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/PlateStatus`)
      .then((res) => {
        setPlateStatusDescs(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Vehicle/GetVehicles/${accountId}`)
      .then((res) => {
        setVehicles(res.data);
        plateStatusLookup();
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.presentError('Server could not be reached. Please refresh the page.');
      });
  }, [refresh]);

  const addVehicle = (newVehicle) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Vehicle/AddVehicle`, newVehicle)
      .then((res) => {
        if (res.data) {
          props.presentAlert(
            'success',
            'New vehicle added successfully',
            `${newVehicle.vin} ${newVehicle.plateNumber}`
          );
          toggleRefresh(!refresh);
        }
      })
      .catch((err) => {
        props.presentAlert(
          'error',
          err.response?.status === 500 ? 'Failed to add vehicle' : err.response?.data.message
        );
      });
  };

  const updateVehicle = (updatedVehicle) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/Vehicle/UpdateVehicle`, updatedVehicle)
      .then((res) => {
        if (res.data) {
          props.presentAlert('success', `Vehicle information was updated successfully`);
          toggleRefresh(!refresh);
        }
      })
      .catch(() => {
        props.presentAlert('error', 'Failed to update vehicle information');
      });
  };

  const removeVehicle = (accountClosureObj, plateId) => {
    const removeVehObj = {
      accountId,
      plateId,
      closePeriodType: accountClosureObj.closePeriodType,
      closureFeeResponse: {
        plateId,
        closeFees: accountClosureObj.closureFeeResponse.closeFees,
      },
      closureType: 'Plate',
    };
    props.setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/Account/Close`, removeVehObj)
      .then((res) => {
        if (res.data === true) {
          props.presentAlert('success', 'Remove vehicle was initiated');
          toggleRefresh(!refresh);
        } else {
          props.presentAlert('error', 'Remove vehicle was not initiated');
        }
        props.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.setLoading(false);
        props.presentAlert(
          'error',
          `Remove vehicle was not initiated. ${err.response?.data?.message}`
        );
      });
  };

  const getPlateStatusDesc = (plateStatusId) => {
    return plateStatusDescs[plateStatusId.toString()];
  };

  const vehiclesMapper = vehicles.map((vehicle, index) => {
    return (
      <VehiclesCard
        vehiclesCount={vehicles.length}
        presentAlert={props.presentAlert}
        index={index}
        key={vehicle.vehicleId}
        vehicle={vehicle}
        removeVehicle={removeVehicle}
        getPlateStatusDesc={getPlateStatusDesc}
        updateVehicle={updateVehicle}
        accountId={accountId}
        accountData={props.accountData}
      />
    );
  });

  return (
    <div>
      {!props.loading && (
        <>
          <FormDialog
            formId="AddVehicle"
            buttonText="Add Vehicle"
            dialogTitle="Add Vehicle"
            disableButton={
              accountStatusId === getAccountStatusIdByDesc(accountStatusDescs, 'Close')
            }
          >
            <AddVehicleDialogForm
              accountData={props.accountData}
              accountId={accountId}
              addVehicle={addVehicle}
              dismissVehiclePageAlert={props.dismissAlert}
            />
          </FormDialog>
          <Stack direction="column" spacing={2}>
            <TableContainer component={Paper}>
              <Table size="small" id="Vehicles-table-vehicles">
                <TableHead>
                  <TableRow>
                    <TableCell>Plate #</TableCell>
                    <TableCell>Plate Id</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Make</TableCell>
                    <TableCell>Model</TableCell>
                    <TableCell>Year</TableCell>
                    <TableCell>Customer #</TableCell>
                    <TableCell>Title #</TableCell>
                    <TableCell>VIN</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Status Date</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>End Of Term Pending Close Date</TableCell>
                    <TableCell align="center" colSpan={2}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{vehiclesMapper}</TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <div style={{ marginTop: '50px' }}>
            <VehicleDetailsGrid vehiclesRefresh={refresh} />
          </div>
        </>
      )}
    </div>
  );
}

VehiclesPage.propTypes = {
  accountData: PropTypes.shape({
    accountId: PropTypes.number.isRequired,
    accountNumber: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentError: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(VehiclesPage);
