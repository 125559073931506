import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import axios from 'utilities/authAxios';
import { withRouter, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { accountStatusDescsAtom } from 'utilities/State';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { convertToDateMMddyyyy } from 'utilities/format';
import FormDialog from 'components/Custom/FormDialog';
import { MenuItem } from '@mui/material';
import WithLoader from 'components/Custom/WithLoader';
import CloseAccountDialogForm from './CloseAccountDialogForm';

function StyledCategory(value) {
  return (
    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
      {' '}
      {value}{' '}
    </Typography>
  );
}

function StyledValue(category, value) {
  return (
    <Typography variant="body1" id={`AccountInfo-text-${category}`}>
      {' '}
      {value}{' '}
    </Typography>
  );
}
function accountInfoPage(props) {
  const { accountId } = useParams();
  const [accountStatusDescs, setAccountStatusDescs] = useRecoilState(accountStatusDescsAtom);
  const [accountTypes, setAccountTypes] = useState({});
  const [contactMethods, setContactMethod] = useState([]);
  const [pendingCloseVeh, setPendingCloseVeh] = useState([]);

  const getAccountTypeDesc = (accountTypeId) => {
    return accountTypes[accountTypeId.toString()];
  };

  const getAccountStatusLookup = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/AccountStatus`)
      .then((res) => {
        setAccountStatusDescs(res.data);
      })
      .catch((err) => console.log(err));
    props.setLoading(false);
  };
  const getAccountTypeLookup = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/AccountType`)
      .then((res) => {
        setAccountTypes(res.data);
      })
      .catch((err) => console.log(err));
    props.setLoading(false);
  };
  const getContactMethod = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/ContactMethod`)
      .then((res) => {
        setContactMethod(res.data);
      })
      .catch((err) => console.log(err));
  };
  const accountClosure = (accountClosureObj) => {
    props.setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/Account/Close`, accountClosureObj)
      .then((res) => {
        props.setLoading(false);
        if (res.data === true) {
          props.reloadParent();
          props.presentAlert('success', 'Account closure was initiated');
        } else {
          props.presentAlert('error', 'Account closure was not initiated');
        }
      })
      .catch((err) => {
        props.setLoading(false);
        props.presentAlert(
          'error',
          `Account closure was not initiated. ${err.response?.data?.message}`
        );
      });
  };
  const getVehicles = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Vehicle/GetVehicles/${parseInt(accountId, 10)}`)
      .then((res) => {
        if (res?.data) {
          setPendingCloseVeh(res.data.filter((x) => x.pendingCloseDate !== null));
        }
      })
      .catch((err) => {
        console.log(err);
        props.presentAlert('error', 'Server could not be reached. Please refresh the page.');
      });
  };

  useEffect(() => {
    getVehicles();
    getContactMethod();
    getAccountStatusLookup();
    getAccountTypeLookup();
  }, []);

  return (
    <div>
      {props.accountData && (
        <Stack direction="column" spacing={2}>
          {props.accountData?.accountStatusId !== 3 && props.accountData?.accountStatusId !== 4 && (
            <Box position="relative">
              <FormDialog
                formId="CloseAccount"
                customButton={<MenuItem id="Close-account-fee-btn-title">Close Account</MenuItem>}
                buttonText="Close Account"
                dialogTitle="Close Account"
              >
                <CloseAccountDialogForm
                  pendingCloseVeh={pendingCloseVeh}
                  accountClosure={accountClosure}
                />
              </FormDialog>
            </Box>
          )}
          <Grid container>
            <Grid item xs={2}>
              {StyledCategory('Account #')}
              {StyledCategory('Account Type')}
              {StyledCategory('Company Name')}
              {StyledCategory('Date Opened')}
              {StyledCategory('First Name')}
              {StyledCategory('Last Name')}
              {StyledCategory('Email')}
              {StyledCategory('Preferred Contact Method')}
              {StyledCategory('Address Line 1')}
              {props.accountData?.addressLine2 && StyledCategory('Address Line 2')}
              {StyledCategory('City')}
              {StyledCategory('State')}
              {StyledCategory('Zip')}
              {StyledCategory('Country')}
              {StyledCategory('Balance')}
              {StyledCategory('Rebill Amount')}
              {StyledCategory('LBT Amount')}
              {StyledCategory('Account Status')}
              {StyledCategory('Status Date')}
              {StyledCategory('Rebill Date')}
            </Grid>
            <Grid item>
              {StyledValue('accountNumber', props.accountData?.accountNumber)}
              {StyledValue('accountType', getAccountTypeDesc(props.accountData?.accountTypeId))}
              {StyledValue(
                'companyName',
                getAccountTypeDesc(props.accountData?.accountTypeId) === 'Commercial' &&
                  props.accountData?.companyName
                  ? props.accountData?.companyName
                  : '-'
              )}
              {StyledValue('openDt', convertToDateMMddyyyy(props.accountData?.openDt))}
              {StyledValue('firstName', props.accountData?.firstName)}
              {StyledValue('lastName', props.accountData?.lastName)}
              {StyledValue('email', props.accountData?.email)}
              {StyledValue('contactMethod', contactMethods[props.accountData?.contactMethod])}
              {StyledValue('addressLine1', props.accountData?.addressLine1)}
              {props.accountData?.addressLine2 &&
                StyledValue('addressLine2', props.accountData?.addressLine2)}
              {StyledValue('city', props.accountData?.city)}
              {StyledValue('state', props.accountData?.state)}
              {StyledValue('postalCode', props.accountData?.postalCode)}
              {StyledValue('country', props.accountData?.country)}
              {StyledValue('accountBalance', `$${props.accountData?.accountBalance.toFixed(2)}`)}
              {StyledValue('rebillAmount', `$${props.accountData?.rebillAmount.toFixed(2)}`)}
              {StyledValue('lbtamount', `$${props.accountData?.lbtamount.toFixed(2)}`)}
              {StyledValue(
                'accountStatus',
                accountStatusDescs[props.accountData?.accountStatusId.toString()] ??
                  props.accountData?.accountStatusId
              )}
              {StyledValue(
                'accountStatusDt',
                props.accountData?.accountStatusDt
                  ? convertToDateMMddyyyy(props.accountData?.accountStatusDt)
                  : '-'
              )}
              {StyledValue(
                'rebillDt',
                props.accountData?.rebillDt
                  ? convertToDateMMddyyyy(props.accountData?.rebillDt)
                  : '-'
              )}
            </Grid>
          </Grid>
        </Stack>
      )}
    </div>
  );
}

accountInfoPage.propTypes = {
  accountData: PropTypes.shape({
    accountId: PropTypes.number,
    accountNumber: PropTypes.string,
    openDt: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    plateNumber: PropTypes.string,
    vin: PropTypes.string,
    accountBalance: PropTypes.number,
    accountStatusId: PropTypes.number,
    accountStatusDt: PropTypes.string,
    contactMethod: PropTypes.number,
  }),
  setLoading: PropTypes.func.isRequired,
};

accountInfoPage.defaultProps = {
  accountData: null,
};

export default withRouter(WithLoader(accountInfoPage));
