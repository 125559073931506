import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormDialog from 'components/Custom/FormDialog';
import EditIcon from '@mui/icons-material/Edit';
import { convertToDateMMddyyyy } from 'utilities/format';
import { useRecoilValue } from 'recoil';
import { vehicleRight } from 'utilities/userRole';
import Delete from '@mui/icons-material/Delete';
import UpdateVehicleFormDialog from './UpdateVehicleFormDialog';
import CloseAccountDialogForm from '../AccountInfo/CloseAccountDialogForm';

export default function VehiclesCard(props) {
  const { vehicle, index, getPlateStatusDesc, presentAlert } = props;
  const plateStatusDesc = getPlateStatusDesc(vehicle.plateStatus);
  const hasVehicleRight = useRecoilValue(vehicleRight);
  const handleClickOpen = () => {
    if (plateStatusDesc === 'Inactive' || plateStatusDesc === 'Pending Close') {
      presentAlert('error', `${plateStatusDesc} vehicle cannot be updated`);
      return false;
    }
    return true;
  };

  const removeVehicle = (accountClosureObj) => {
    props.removeVehicle(accountClosureObj, vehicle.vehicleId);
  };

  return (
    <TableRow id={`Vehicles-tableRow-vehicle-${index}`}>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-plateNumber`}>
        {vehicle.plateNumber}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-plateId`}>{vehicle.vehicleId}</TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-state`}>{vehicle.state}</TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-country`}>{vehicle.country}</TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-vehicleMake`}>
        {vehicle.vehicleMake}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-vehicleModel`}>
        {vehicle.vehicleModel}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-vehicleYear`}>
        {vehicle.vehicleYear}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-customerNo`}>
        {vehicle.customerNo}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-rucKey`}>
        {vehicle.visKey === null ? '-' : vehicle.visKey}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-vin`}>{vehicle.vin}</TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-plateStatus`}>
        {plateStatusDesc}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-statusDate`}>
        {convertToDateMMddyyyy(vehicle.statusDate)}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-startDate`}>
        {convertToDateMMddyyyy(vehicle.startDate)}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-endDate`}>
        {vehicle.endDate === null ? '' : convertToDateMMddyyyy(vehicle.endDate)}
      </TableCell>
      <TableCell id={`Vehicles-tableCell-vehicle-${index}-pendingCloseDate`}>
        {vehicle.pendingCloseDate === null
          ? ''
          : convertToDateMMddyyyy(vehicle.pendingCloseDate, false)}
      </TableCell>
      <TableCell
        padding="none"
        align="center"
        id={`Vehicles-tableCell-vehicle-${index}-updateVehicle`}
      >
        {hasVehicleRight && (
          <FormDialog
            formId="Update Vehicle"
            buttonIcon={<EditIcon />}
            dialogTitle="Update Vehicle"
            handleParentOpen={handleClickOpen}
          >
            <UpdateVehicleFormDialog
              accountId={props.accountId}
              vehicle={vehicle}
              updateVehicle={props.updateVehicle}
              accountData={props.accountData}
            />
          </FormDialog>
        )}
      </TableCell>
      <TableCell
        padding="none"
        align="center"
        id={`Vehicles-tableCell-vehicle-${index}-removeVehicle`}
      >
        {hasVehicleRight &&
        vehicle.endDate === null &&
        plateStatusDesc !== 'Inactive' &&
        plateStatusDesc !== 'Pending Close' ? (
          <FormDialog
            formId="Remove Vehicle"
            buttonIcon={<Delete />}
            dialogTitle="Remove Vehicle"
            handleParentOpen={handleClickOpen}
          >
            <CloseAccountDialogForm plate={vehicle} accountClosure={removeVehicle} />
          </FormDialog>
        ) : (
          ''
        )}
      </TableCell>
    </TableRow>
  );
}

VehiclesCard.propTypes = {
  accountData: PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
  vehicle: PropTypes.shape({
    plateNumber: PropTypes.string.isRequired,
    vehicleId: PropTypes.number.isRequired,
    state: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    vehicleMake: PropTypes.string.isRequired,
    vehicleModel: PropTypes.string.isRequired,
    vehicleYear: PropTypes.string.isRequired,
    dmvRegDate: PropTypes.string.isRequired,
    visKey: PropTypes.string.isRequired,
    customerNo: PropTypes.string.isRequired,
    capFee: PropTypes.string.isRequired,
    dmvExpDate: PropTypes.string.isRequired,
    accruedCharges: PropTypes.number.isRequired,
    vin: PropTypes.string.isRequired,
    plateStatus: PropTypes.number.isRequired,
    plateId: PropTypes.number.isRequired,
    statusDate: PropTypes.string.isRequired,
    IsEarlyEnrollee: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    pendingCloseDate: PropTypes.string.isRequired,
  }).isRequired,
  accountId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  getPlateStatusDesc: PropTypes.func.isRequired,
  updateVehicle: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  removeVehicle: PropTypes.func.isRequired,
};
