/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import FormDialog from 'components/Custom/FormDialog';
import { convertToDateMMddyyyy, convertToDateTime } from 'utilities/format';
import ReversalDialogForm from 'components/Custom/ReversalDialogForm';
import { useRecoilValue } from 'recoil';
import { creditAdjustmentRight, refundRight } from 'utilities/userRole';
import CustomTextDataGridCell from 'components/Custom/CustomTextDataGridCell';

const DataGridCell = ({ id, width, children, center, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} center={center} rowHeight={rowHeight}>
    {children}
  </CustomTextDataGridCell>
);

const ActionCell = ({
  id,
  width,
  transaction,
  postTransactionReversal,
  handleClickOpen,
  rowHeight,
  mileageReversalReasons,
  canAccess,
}) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    {transaction.amountPaid > 0 && transaction.isReversed === false && canAccess ? (
      <FormDialog
        formId="MileageTransactionReversal"
        buttonIcon={<SettingsBackupRestoreIcon />}
        dialogTitle="Mileage Transaction Reversal"
        handleParentOpen={handleClickOpen}
      >
        <ReversalDialogForm
          reasonCodes={mileageReversalReasons}
          transaction={transaction}
          postReversal={postTransactionReversal}
          parentId="MileageTransactionReversal"
        />
      </FormDialog>
    ) : (
      ''
    )}
  </DataGridCell>
);

const IsReversedCell = ({ id, width, transaction, rowHeight }) => (
  <DataGridCell id={id} width={width} center rowHeight={rowHeight}>
    <Checkbox disabled checked={transaction.isReversed} />
  </DataGridCell>
);

const DateCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateMMddyyyy(children) : ''}
  </CustomTextDataGridCell>
);

const DateTimeCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? convertToDateTime(children) : ''}
  </CustomTextDataGridCell>
);

const AmountCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children ? `$${children?.toFixed(2)}` : '$0.00'}
  </CustomTextDataGridCell>
);

const OdometerReadingCell = ({ id, width, children, rowHeight }) => (
  <CustomTextDataGridCell id={id} width={width} rowHeight={rowHeight}>
    {children || '-'}
  </CustomTextDataGridCell>
);

const CELL_TYPE = {
  isReversed: IsReversedCell,
  entryTransDt: DateCell,
  postDt: DateCell,
  entryTime: DateTimeCell,
  action: ActionCell,
  price: AmountCell,
  amountPaid: AmountCell,
  odometerReading: OdometerReadingCell,
};

export default function TransactionsCard(props) {
  const { transaction, index, mileageReversalReasons, postTransactionReversal } = props;
  const updatedTransaction = { ...transaction, entryTime: transaction.entryTransDt };
  const widthMap = useMemo(() => {
    return props.renderedColumns.reduce((acc, col) => {
      acc[col.field] = col.computedWidth;
      return acc;
    }, {});
  }, [props.renderedColumns]);
  const hasRefundRight = useRecoilValue(refundRight);
  const hasCreditAdjustmentRight = useRecoilValue(creditAdjustmentRight);

  return (
    <TableRow
      id={`Transactions-tableRow-transaction-${index}`}
      sx={{
        backgroundColor: index % 2 ? 'transparent' : 'action.hover',
        ':hover': { backgroundColor: 'rgb(196, 196, 196)' },
        display: 'flex',
      }}
    >
      {props.renderedColumns.map((col) => {
        const CellComponent = CELL_TYPE[col.field] || DataGridCell;

        return (
          <CellComponent
            id={`Transactions-tableCell-transaction-${index}-${col.field}`}
            key={col.field}
            width={widthMap[col.field]}
            transaction={updatedTransaction}
            mileageReversalReasons={mileageReversalReasons}
            postTransactionReversal={postTransactionReversal}
            index={index}
            rowHeight={props.rowHeight}
            canAccess={hasCreditAdjustmentRight || hasRefundRight}
          >
            {updatedTransaction[col.field]}
          </CellComponent>
        );
      })}
    </TableRow>
  );
}

TransactionsCard.propTypes = {
  transaction: PropTypes.shape({
    tollTransactionId: PropTypes.number.isRequired,
    transMasterId: PropTypes.number.isRequired,
    plateNumber: PropTypes.string.isRequired,
    entryTransDt: PropTypes.string.isRequired,
    postDt: PropTypes.string.isRequired,
    exitTranDt: PropTypes.string.isRequired,
    exitTransDt: PropTypes.string.isRequired,
    entryPlazaId: PropTypes.number.isRequired,
    entryLaneId: PropTypes.number.isRequired,
    directionId: PropTypes.string.isRequired,
    webBalance: PropTypes.number.isRequired,
    amountPaid: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    fjNo: PropTypes.number.isRequired,
    isReversed: PropTypes.bool.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  renderedColumns: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
  postTransactionReversal: PropTypes.func.isRequired,
  mileageReversalReasons: PropTypes.arrayOf(
    PropTypes.shape({
      finTransTypeId: PropTypes.number.isRequired,
      formText: PropTypes.string.isRequired,
      reasonCodeDesc: PropTypes.string.isRequired,
      reasonCodeId: PropTypes.number.isRequired,
    })
  ).isRequired,
};

DataGridCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

DataGridCell.defaultProps = {
  center: false,
};
DateCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

IsReversedCell.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
};
