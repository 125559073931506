import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from 'utilities/authAxios';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import { convertToDate } from 'utilities/format';
import TextField from '@mui/material/TextField';
import { Alert, Typography } from '@mui/material';

export default function CloseAccountDialogForm(props) {
  const { handleClose } = props;
  const [accountClosureReasons, setAccountClosureReasons] = useState({});
  const [closureFees, setClosureFees] = useState([]);
  const [closureType, setClosureType] = useState(1);
  const [closureReason, setClosureReason] = useState(0);
  const { accountId } = useParams();
  const isAccount = props.plate === null;
  const idPrefix = isAccount ? 'CloseAccount' : 'ClosePlate';

  const handleSubmit = (e) => {
    e.preventDefault();
    const accountClosureObj = {
      accountId: parseInt(accountId, 10),
      closeReasonId: closureReason,
      closePeriodType: closureType,
      closureFeeResponse: {
        accountId: parseInt(accountId, 10),
        closeFees: closureFees.filter((x) => x.closeType === closureType),
      },
      closureType: 'Account',
    };
    props.accountClosure(accountClosureObj);
    handleClose();
  };

  const handleChangeReasonType = (e) => {
    setClosureType(parseInt(e.target.value, 10));
  };

  const handleChangedReason = (e) => {
    setClosureReason(parseInt(e.target.value, 10));
  };

  const getAccountClosureReasons = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Lookup/CloseReason`)
      .then((res) => {
        setAccountClosureReasons(res.data);
      })
      .catch(() => {
        props.presentAlert('error', 'Failed to retrieve close reasons');
      });
  };

  const getAccountClosureResponses = () => {
    props.setLoading(true);
    const optoutRequest = {
      accountId,
      plateId: props.plate?.vehicleId ?? null,
      closureType: 'Account',
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/Account/CloseOptoutEligibilityCheck`, optoutRequest)
      .then((res) => {
        if (res.data.closeFees.length !== 0) {
          setClosureFees(res.data.closeFees);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          props.presentAlert('error', 'Failed to retrieve closure fees');
        }
      })
      .catch((err) => {
        props.setLoading(false);
        props.presentAlert(
          'error',
          err.response?.status === 400
            ? err.response?.data.message
            : 'Failed to retrieve closure fees'
        );
      });
  };

  const getPlateClosureResponses = () => {
    props.setLoading(true);

    props.setLoading(true);
    const optoutRequest = {
      accountId,
      plateId: props.plate?.vehicleId ?? null,
      closureType: 'Plate',
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/Account/CloseOptoutEligibilityCheck`, optoutRequest)
      .then((res) => {
        if (res.data.closeFees.length !== 0) {
          props.setLoading(false);
          setClosureFees(res.data.closeFees);
        } else {
          props.setLoading(false);
          props.presentAlert('error', 'Failed to retrieve closure fees');
        }
      })
      .catch((err) => {
        props.setLoading(false);
        props.presentAlert(
          'error',
          err.response?.status === 400
            ? err.response?.data.message
            : 'Failed to retrieve closure fees'
        );
      });
  };

  const feeRows = Object.entries(closureFees.filter((x) => x.closeType === closureType)).map(
    (fee) => {
      return (
        <TableRow sx={fee[1].mustCloseImmediate === true ? { color: 'red' } : {}}>
          <TableCell style={{ width: 'auto', textAlign: 'center' }}>
            {fee[1].vin.replaceAll(' ', '')}
          </TableCell>
          <TableCell style={{ width: 'auto', textAlign: 'center' }}>
            {fee[1].plateNumber.replaceAll(' ', '')}
          </TableCell>
          <TableCell style={{ width: 'auto', textAlign: 'center' }}>
            {convertToDate(fee[1].closeDate)}
          </TableCell>
        </TableRow>
      );
    }
  );

  const pendingVeh = props.pendingCloseVeh.map((pVeh) => {
    return (
      <li>
        {pVeh.vehicleYear} {pVeh.vehicleMake} {pVeh.vehicleModel} {pVeh.plateNumber}
      </li>
    );
  });

  useEffect(() => {
    if (isAccount) {
      getAccountClosureReasons();
      getAccountClosureResponses();
    } else {
      getPlateClosureResponses();
    }
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Typography component="div">
            You may choose to {isAccount ? 'close your account' : 'remove your vehicle'} immediately
            or at the end of your Mileage Choice period.
          </Typography>
          {closureType === 1 && (
            <Typography component="div">
              If you have sold or registered your vehicle in another state, your plates must first
              be surrendered to the VA DMV.
              <br />
              If your plates are not surrendered within 30 days your payment method on file will be
              charged the difference between your total registration period Highway Use Fee amount
              and your total Mileage Choice payments to date.
              <br />
              Your final total owed will be sent via email on the closure date below. Press
              {isAccount ? '"Close Account"' : '"Remove Vehicle"'} to proceed with {''}
              {isAccount
                ? 'closing the account or press "Cancel" to not close the account.'
                : 'removing the vehicle or press "Cancel" to not remove the vehicle.'}
            </Typography>
          )}
          {closureType === 2 && (
            <Typography component="div">
              If you have sold or registered your vehicle in another state, your plates must first
              be surrendered to the VA DMV.
              <br />
              If your plates are not surrendered within 30 days your payment method on file will be
              charged the difference between your total registration period Highway Use Fee amount
              and your total Mileage Choice payments to date.
              <br />
              Your final total owed will be sent via email on the closure date below. Press
              {isAccount ? '"Close Account"' : '"Remove Vehicle"'} to proceed with {''}
              {isAccount
                ? 'closing the account or press "Cancel" to not close the account.'
                : 'removing the vehicle or press "Cancel" to not remove the vehicle.'}
            </Typography>
          )}
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl>
                {isAccount ? (
                  <FormLabel id="CloseAccount-label-type">Close Account Type</FormLabel>
                ) : (
                  <FormLabel id="ClosePlate-label-type">Close Plate Type</FormLabel>
                )}
                <RadioGroup
                  row
                  aria-labelledby="immediateclose-radio-buttons-group-label"
                  defaultValue="1"
                  name="closetype-radiobuttons-group"
                >
                  <FormControlLabel
                    value="1"
                    label="Close Immediately"
                    control={<Radio />}
                    onChange={handleChangeReasonType}
                  />
                  <FormControlLabel
                    value="2"
                    label="Close at End of Term"
                    control={<Radio />}
                    disabled={
                      closureFees.filter((x) => x.mustCloseImmediate === false).length === 0
                    }
                    onChange={handleChangeReasonType}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {isAccount && (
              <Grid item xs={6}>
                <Box>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="Reason"
                    id={`${idPrefix}-select-reason`}
                    value={closureReason}
                    name="reason"
                    onChange={handleChangedReason}
                  >
                    {Object.entries(accountClosureReasons).map((option) => (
                      <MenuItem
                        id={`${idPrefix}-select-reason-${option[1]
                          .replaceAll(' ', '')
                          .toLowerCase()}`}
                        value={option[0]}
                      >
                        {option[1]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
            )}
            {closureFees.length > 0 && (
              <Fade in timeout={500}>
                <Grid item xs={12}>
                  <Table style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: 'auto', textAlign: 'center' }}>VIN</TableCell>
                        <TableCell style={{ width: 'auto', textAlign: 'center' }}>PLATE</TableCell>
                        <TableCell style={{ width: 'auto', textAlign: 'center' }}>
                          CLOSURE DATE
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {feeRows}
                  </Table>
                  <br />
                  {closureType === 1 && props.pendingCloseVeh.length > 0 && (
                    <Alert
                      icon={false}
                      variant="outlined"
                      severity="error"
                      sx={{ color: 'red', m: 1 }}
                    >
                      <Typography component="div">
                        You previously requested that the following{' '}
                        {props.pendingCloseVeh.length > 1 ? 'vehicles' : 'vehicle'}:{' '}
                        <ul>{pendingVeh}</ul>
                        be removed from the Mileage Choice Program at the end of your registration
                        term. If you close your account, these vehicles will be moved to close
                        immediately.
                      </Typography>
                    </Alert>
                  )}
                  {closureFees.filter((x) => x.mustCloseImmediate === true).length > 0 &&
                    closureType === 2 && (
                      <Alert
                        icon={false}
                        variant="outlined"
                        severity="error"
                        sx={{ color: 'red', m: 1 }}
                      >
                        <Typography component="span">
                          {closureFees.filter((x) => x.mustCloseImmediate === true).length === 2
                            ? 'This vehicle'
                            : 'These vehicles'}{' '}
                          cannot be removed at the end of its Mileage Choice period. It will be
                          removed immediately.
                        </Typography>
                      </Alert>
                    )}
                </Grid>
              </Fade>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id={`${idPrefix}-btn-cancel`}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            id={`${idPrefix}-btn-close`}
            disabled={
              closureFees.length === 0 ||
              (props.plate == null && closureReason === 0) ||
              (props.plate != null && closureFees.length === 0)
            }
            type="submit"
          >
            {isAccount ? 'Close Account' : 'Remove Vehicle'}
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

CloseAccountDialogForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  accountClosure: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  plate: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  pendingCloseVeh: PropTypes.arrayOf(
    PropTypes.shape({
      plateNumber: PropTypes.string,
      vehicleId: PropTypes.number,
      state: PropTypes.string,
      country: PropTypes.string,
      vehicleMake: PropTypes.string,
      vehicleModel: PropTypes.string,
      vehicleYear: PropTypes.string,
      dmvRegDate: PropTypes.string,
      visKey: PropTypes.string,
      capFee: PropTypes.string,
      dmvExpDate: PropTypes.string,
      accruedCharges: PropTypes.number,
      vin: PropTypes.string,
      plateStatus: PropTypes.number,
      plateId: PropTypes.number,
      statusDate: PropTypes.string,
      IsEarlyEnrollee: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      pendingCloseDate: PropTypes.string,
    })
  ),
};

CloseAccountDialogForm.defaultProps = {
  plate: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  pendingCloseVeh: [],
};
